// Variables

$border-radius: 0 !default;
$border-radius-lg: 0 !default;
$border-radius-sm: 0 !default;

// Navbar

.navbar {
  font-weight: $headings-font-weight;
}

.bg-primary {
  .dropdown-menu {
    background-color: $primary;

    .dropdown-item,
    .dropdown-item:focus {
      color: $navbar-dark-color;
    }

    .dropdown-item.active,
    .dropdown-item:hover,
    .dropdown-item:focus {
      background-color: shade-color($primary, 10%);
      color: $white;
    }
  }
}

.bg-dark {
  background-color: $gray-800 !important;

  .dropdown-menu {
    background-color: $gray-800;

    .dropdown-item,
    .dropdown-item:focus {
      color: $navbar-dark-color;
    }

    .dropdown-item.active,
    .dropdown-item:hover,
    .dropdown-item:focus {
      background-color: shade-color($gray-800, 10%);
      color: $white;
    }
  }
}

.bg-light {
  .dropdown-menu {
    background-color: $gray-200;

    .dropdown-item,
    .dropdown-item:focus {
      color: $navbar-dark-color;
    }

    .dropdown-item.active,
    .dropdown-item:hover,
    .dropdown-item:focus {
      background-color: tint-color($gray-200, 10%);
      color: $navbar-dark-hover-color;
    }
  }
}

// Buttons

.btn {
  border-radius: 0;

  &-primary {
    border-color: shade-color($primary, 10%);
    color: $white;
  }

  &-primary:hover,
  &-primary:focus {
    color: $white;
  }

  &-secondary {
    border-color: shade-color($secondary, 10%);
  }

  &-success {
    border-color: shade-color($success, 10%);
  }

  &-success:hover,
  &-success:focus {
    color: $white;
  }

  &-info {
    border-color: shade-color($info, 10%);
    color: $white;
  }

  &-info:hover,
  &-info:focus {
    color: $white;
  }

  &-danger {
    border-color: shade-color($danger, 10%);
  }

  &-warning {
    border-color: shade-color($warning, 10%);
    color: $white;
  }

  &-light {
    border-color: shade-color($light, 10%);
  }

  &-dark {
    border-color: shade-color($dark, 10%);
  }
}

// .btn:hover,
// .btn:focus {
//   color: $white;
// }

.btn-group {
  border-radius: 0;

  .dropdown-menu {
    border-top-width: 0;
  }

  .dropdown-toggle {
    &.btn-primary~.dropdown-menu {
      background-color: $primary;
      border-color: shade-color($primary, 10%);

      .dropdown-item {
        color: $white;
      }

      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: shade-color($primary, 16%);
        color: $white;
      }
    }

    &.btn-secondary~.dropdown-menu {
      background-color: $secondary;
      border-color: shade-color($secondary, 10%);

      .dropdown-item {
        color: $body-color;
      }

      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: shade-color($secondary, 16%);
        color: $white;
      }
    }

    &.btn-success~.dropdown-menu {
      background-color: $success;
      border-color: shade-color($success, 10%);

      .dropdown-item {
        color: $white;
      }

      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: shade-color($success, 16%);
        color: $white;
      }
    }

    &.btn-info~.dropdown-menu {
      background-color: $info;
      border-color: shade-color($info, 10%);

      .dropdown-item {
        color: $white;
      }

      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: shade-color($info, 16%);
        color: $white;
      }
    }

    &.btn-warning~.dropdown-menu {
      background-color: $warning;
      border-color: shade-color($warning, 10%);

      .dropdown-item {
        color: $white;
      }

      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: shade-color($warning, 16%);
        color: $white;
      }
    }

    &.btn-danger~.dropdown-menu {
      background-color: $danger;
      border-color: shade-color($danger, 10%);

      .dropdown-item {
        color: $white;
      }

      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: shade-color($danger, 16%);
        color: $white;
      }
    }
  }
}

// Typography

.text-secondary {
  color: $gray-700 !important;
}

.blockquote-footer {
  color: $gray-600;
}

// Tables

table {
  font-size: $font-size-sm;
}

// Forms

label,
.control-label,
.help-block,
.checkbox,
.radio,
.form-control-feedback {
  font-size: $font-size-sm;
}


// Navs

.dropdown-item {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: $font-size-sm;
  font-weight: 300;
}

.nav-tabs {

  .nav-link,
  .nav-link.disabled,
  .nav-link.disabled:hover,
  .nav-link.disabled:focus {
    border-color: $nav-tabs-border-color;
    background-color: $gray-200;
  }

  .nav-link:hover,
  .nav-link:focus {
    background-color: tint-color($gray-200, 10%);
  }
}

.nav-pills {
  .active {
    border: 1px solid shade-color($primary, 10%);
  }
}

// .breadcrumb {
//   border: 1px solid $dropdown-border-color;
//   font-size: $font-size-sm;
//   font-weight: 300;
//   text-transform: uppercase;
// }

// .pagination {
//   font-weight: 300;

//   .page-link {
//     border-color: transparent;
//     border-radius: 3px;
//     margin-left: .1em;
//     margin-right: .1em;

//     &:hover,
//     &:focus {
//       text-decoration: none;
//     }
//   }

//   .page-item.disabled {
//     .page-link {
//       border-color: transparent;
//     }
//   }
// }

// .pagination,
// .pagination-lg,
// .pagination-sm {

//   .page-item:first-child,
//   .page-item:last-child {
//     .page-link {
//       border-radius: 3px;
//     }
//   }
// }

.list-group {
  font-size: $font-size-sm;
  font-weight: 300;
}

// Indicators

.alert {
  font-size: $font-size-sm;
  font-weight: 300;
  color: $white;

  .alert-link {
    color: $white;
    text-decoration: underline;
  }

  &:not(.alert-secondary):not(.alert-light) .btn-close {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
  }

  &-secondary,
  &-light {

    &,
    a:not(.btn),
    .alert-link {
      color: $body-color;
    }
  }
}

.badge {

  &.bg-secondary,
  &.bg-light {
    color: $dark;
  }
}

// Progress bars

.progress[value] {
  height: 22px;
  padding: 2px;
  background-color: #f6f6f6;
  border: 1px solid #ccc;
  @include box-shadow(none);
}

// Popovers

.popover-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}