//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

//Smooth Scroll
html {
    scroll-behavior: smooth;
}

/* Padding below the footer and lighter body text */

body {
    padding-top: 4rem;
    padding-bottom: 3rem;
    color: #5a5a5a;
}


//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/slider";
@import "variable_overrides";